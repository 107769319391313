import React from "react";
import pathways_to_reading from "../../assets/images/pathways_to_reading.png";

const LessonLogo = ({ altText, hoverMessage }) => {
  let imgSrc;

  switch (altText) {
    case "Pathways to Reading":
      imgSrc = pathways_to_reading;
      break;
    default:
      console.log("LessonLogo: altText not recognized: ", altText);
      imgSrc = null;
      break;
  }

  if (imgSrc === null) {
    return null;
  }

  return (
    <div style={{ position: "absolute", top: 12, right: 12 }}>
      <button
        assignment-data-title={hoverMessage}
        style={{ border: "none", backgroundColor: "transparent" }}
      >
        <img
          src={imgSrc}
          alt={altText}
          style={{ width: "60px", height: "50px" }}
        />
      </button>
    </div>
  );
};

export default LessonLogo;
