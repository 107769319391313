import React from "react";
import ClosePopupBtn from "../../../../UtilComps/ClosePopupBtn.js";
import LessonLogo from "../../../LessonLogo.js";

const SelectedLessonInfoPopup = ({ lesson, setShowingLessonInfo }) => {
  return (
    <div className='pdf-upload'>
      <div className='pdf-upolad-wrapper'>
        <div
          id='step_four_selected_lesson_info_popup'
          className='common_border'
        >
          <div className='common_heading' style={{ textAlign: "left" }}>
            <p>Lesson Info: {lesson.name || lesson.lesson_name}</p>
            <ClosePopupBtn closePopupFunc={setShowingLessonInfo} />
          </div>
          <div
            className='reset_pw_modal common_dashboard_bg'
            style={{ position: "relative" }}
          >
            {lesson !== null && lesson.is_content_partner_lesson && (
              <LessonLogo
                altText={lesson.lesson_source}
                hoverMessage={lesson.lesson_source}
              />
            )}

            <div className='form-content' style={{ textAlign: "left" }}>
              <div>
                <p>
                  <b style={{ marginRight: "5px" }}>Name:</b>{" "}
                  {lesson.lesson_name}
                </p>

                <p>
                  <b style={{ marginRight: "5px" }}>Course:</b>{" "}
                  {lesson.course_name}
                </p>

                <p>
                  <b style={{ marginRight: "5px" }}>Unit:</b> {lesson.unit}
                </p>

                {!lesson.course_name.startsWith("SEL") && (
                  <p>
                    <b style={{ marginRight: "5px" }}>Standard:</b>{" "}
                    {lesson.standard || lesson.standard_code}
                  </p>
                )}

                <p>
                  <b style={{ marginRight: "5px" }}>Standard Description:</b>{" "}
                  {lesson.standard_description}
                </p>

                <p>
                  <b style={{ marginRight: "5px" }}>Type:</b> {lesson.type}
                </p>
              </div>
              <div style={{ textAlign: "center" }}>
                <button
                  className='btn btn-lg fs-14 btn-primary col-md-2'
                  onClick={() => setShowingLessonInfo(null)}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectedLessonInfoPopup;
