import React, { useEffect, useState } from "react";
import Select from "react-select";
import LessonLogo from "../LessonLogo";

import FAW from "../../../containers/faw";

const ScoresByLessonTable = ({
  truncatedStandard,
  assignment,
  currentLesson,
  currentLearner,
  setCurrentLesson,
  findScore,
  setLessonForPopupInfo,
  setShowPopupInfo,
  scrollStyles,
}) => {
  const [average, setAverage] = useState("-");
  const [lessonsCompleted, setLessonsCompleted] = useState(null);

  /**
   * getAverage sets the average for the current lesson's learner scores.
   * @returns none
   */
  const getAverage = () => {
    if (!assignment || !assignment.performances || currentLesson === null)
      return;
    let scores = assignment.performances;
    if (scores.length === 0) return "-";
    let scoresSum = "-";
    let count = 0;

    for (let i = 0; i < assignment.learners.length; i++) {
      let score = findScore(
        assignment.lessons[currentLesson].lesson_id,
        assignment.learners[i].id,
        assignment
      );

      if (score && score !== "-") {
        count++;
        if (scoresSum === "-") {
          scoresSum = score;
        } else {
          scoresSum = scoresSum + score;
        }
      }
    }
    setAverage(
      scoresSum === "-" ? scoresSum : Math.round((scoresSum / count) * 10) / 10
    );
  };

  const buildLessonDropdownOptions = () => {
    return assignment.lessons.map((lesson, index) => {
      const courseName = lesson.course_name;
      const standard = getTruncatedStandard(lesson);
      const lessonType = lesson.type;
      const isLessonCompleted = lesson.done;
      let dropdownOption = ` (${courseName}) - ${lessonType}`;

      if (courseName.startsWith("SEL")) {
        dropdownOption = lesson.lesson_name + dropdownOption;
      } else {
        dropdownOption = standard + dropdownOption;
      }

      return {
        value: index,
        standard_code: standard,
        label: (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>{dropdownOption}</div>
            <div style={{ color: "green" }}>
              {isLessonCompleted ? "Done" : ""}
            </div>
          </div>
        ),
      };
    });
  };

  const getInfoText = (lesson) => {
    let infoText = getTruncatedStandard(lesson);
    if (infoText === "") {
      infoText = lesson.course_name;
    }
    return infoText;
  };

  const getTruncatedStandard = (lesson) => {
    //we don't have actual standards for SEL lessons so we just return an empty string
    let standard = "";
    if (!lesson.course_name.startsWith("SEL")) {
      standard = truncatedStandard(lesson.standard_code);
    }
    return standard;
  };

  /**
   * getNumberOfLearnersCompleted calculates the percent of learners who
   * submitted the assignment.
   * @returns none
   */
  const getNumberOfLearnersCompleted = () => {
    if (currentLesson === null || !assignment) return;
    let numOfLearners = assignment.learners.length;
    // if no scores are in
    if (!assignment.performances || numOfLearners === 0) {
      setLessonsCompleted(`0% (0/${numOfLearners} learners)`);
      return;
    }
    let lessonScoresList = assignment.performances.filter(
      (val) => val.lesson_id === assignment.lessons[currentLesson].lesson_id
    );
    let learnerIds = [];
    // get number of unique learners who have scores
    for (let i = 0; i < lessonScoresList.length; i++) {
      if (learnerIds.indexOf(lessonScoresList[i].learner_id) === -1) {
        learnerIds = [...learnerIds, lessonScoresList[i].learner_id];
      }
    }
    setLessonsCompleted(
      `${Math.round((learnerIds.length / numOfLearners) * 1000) / 10}% (${
        learnerIds.length
      }/${numOfLearners} learners)`
    );
  };

  /**
   * buildRow creates a row in the table for a score.
   * @param {Object} learner
   * @param {int} index
   * @returns {html} the row in the table, containing the learner name and
   * score value.
   */
  const buildRow = (learner, index) => {
    let score = findScore(
      assignment.lessons[currentLesson].lesson_id,
      learner.id,
      assignment
    );

    return (
      <div
        key={index}
        className='learners_lessons_list_table_row'
        style={{ backgroundColor: index % 2 === 0 ? "#F2F2F2" : "white" }}
      >
        <p
          style={{
            borderRight: "1px solid #cbcbcb",
            paddingRight: "10px",
          }}
        >
          {learner.last_name}, {learner.first_name}
        </p>
        <div>
          <p
            className={`data ${
              score <= 50
                ? "red"
                : score <= 75
                ? "yellow"
                : score <= 100
                ? "green"
                : ""
            }`}
          >
            {`${score !== "-" ? score + "%" : score}`}
          </p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getNumberOfLearnersCompleted();
    getAverage();
  }, [currentLesson]);

  return (
    <div
      className={`expanded_assignment_graybox lesson_graybox ${
        currentLesson === null && currentLearner === null
          ? "maxHeight-graybox-empty"
          : "maxHeight-graybox-filled"
      }`}
      style={{ position: "relative" }}
    >
      <p style={{ textAlign: "center" }}>
        <b>Scores by lesson</b>
      </p>

      <div style={{ margin: "5px" }}>
        <Select
          id='assignment_select_lesson_dropdown'
          styles={scrollStyles}
          isSearchable={false}
          onChange={(selected) => {
            setCurrentLesson(selected.value);
          }}
          defaultValue={{ value: "None", label: "None" }}
          placeholder='Select a filter'
          options={buildLessonDropdownOptions()}
        />

        {currentLesson !== null ? (
          <div className='info_table'>
            {assignment.lessons[currentLesson].is_content_partner_lesson && (
              <LessonLogo
                altText={assignment.lessons[currentLesson].lesson_source}
                hoverMessage={assignment.lessons[currentLesson].lesson_source}
              />
            )}

            <p>
              <b>Course: </b>
              {assignment.lessons[currentLesson].course_name}
            </p>
            <p>
              <b>Unit: </b>
              {assignment.lessons[currentLesson].unit}
            </p>
            <p>
              <b>% Overall Lesson Completion: </b>
              {lessonsCompleted}
            </p>
          </div>
        ) : (
          <p style={{ margin: "10px", textAlign: "center" }}>
            Choose a lesson from the dropdown to begin.
          </p>
        )}
      </div>
      {currentLesson !== null && (
        <div className='learners_lessons_list_table_container'>
          <div className='learners_lessons_list_table_hr'>
            <p
              className='header_label'
              style={{ borderRight: "1px solid #cbcbcb" }}
            >
              <b>Learners</b>
            </p>
            <div style={{ textAlign: "center" }}>
              {/* TODO: create a component for this text and info button */}
              <p>
                <b>
                  {getInfoText(assignment.lessons[currentLesson])}
                  <button
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      padding: "0px",
                      marginLeft: "5px",
                    }}
                    onClick={() => {
                      setLessonForPopupInfo(assignment.lessons[currentLesson]);
                      setShowPopupInfo(true);
                    }}
                  >
                    <FAW icon='info-circle' className='info-circle' />
                  </button>
                </b>
              </p>
              <p>{assignment.lessons[currentLesson].lesson_name}</p>
            </div>
          </div>

          <div className='learners_lessons_list_table_rows_container visible-scrollbar'>
            {assignment.learners.map((learner, index) => {
              return buildRow(learner, index);
            })}
          </div>

          <div
            className='learners_lessons_list_table_ft'
            style={{
              padding:
                assignment.learners.length >= 8 ? "0px 18px 0px 10px" : "",
            }}
          >
            <p
              className='footer_label'
              style={{ borderRight: "1px solid #cbcbcb" }}
            >
              <b>Average</b>
            </p>
            <p
              className={`data ${
                average <= 50
                  ? "red"
                  : average <= 75
                  ? "yellow"
                  : average <= 100
                  ? "green"
                  : ""
              }`}
            >
              {average === "-" ? average : `${average}%`}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScoresByLessonTable;
