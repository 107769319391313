import { connect } from "react-redux";
import { compose } from "redux";
import config from "../common/config";
import { fetchWithError, fetchDownloadFile } from "./http";
import getQSyncerConn from "./qsyncer";
import axios from "axios";

const _registerSyncHandler = (callback) => {
  let qsyncerWSConnection = getQSyncerConn();
  if (qsyncerWSConnection.readyState !== WebSocket.OPEN) {
    qsyncerWSConnection = getQSyncerConn();
  }
  qsyncerWSConnection.addEventListener("message", callback);
};
const _unregisterSyncHandler = (callback) => {
  let qsyncerWSConnection = getQSyncerConn();
  if (qsyncerWSConnection.readyState !== WebSocket.OPEN) {
    qsyncerWSConnection = getQSyncerConn();
  }
  qsyncerWSConnection.removeEventListener("message", callback);
};

var requestID = 0;
const mapStateToProps = (state, ownProps?) => ({
  token: state.auth.token,
  user: state.auth.user,
});

const apiFactory = (token, user) => {
  const queryParams2Str = (queryParams) => {
    let queryStr = "";
    if (queryParams) {
      queryStr = new URLSearchParams(queryParams).toString();
      queryStr = "?" + queryStr;
    }
    return queryStr;
  };

  const getDataNoAuth = () => {
    return {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
  };

  const getData = () => {
    let d = getDataNoAuth();
    d.headers["Authorization"] = `Token ${token}`;
    return d;
  };

  const patchData = (body) => {
    let data = getData(token);
    data.method = "PATCH";
    data.body = body;
    return data;
  };

  const postData = (body) => {
    let data = getData(token);
    data.method = "POST";
    data.body = body;

    return data;
  };

  const postDataNoAuth = (body) => {
    let data = getDataNoAuth(token);
    data.method = "POST";
    data.body = body;

    return data;
  };

  const deleteData = (body = null) => {
    let data = getData(token);
    data.method = "DELETE";
    if (body) {
      data.body = body;
    }

    return data;
  };

  // Robot-only stuff
  const promisifySyncCall = (data) => {
    requestID += 1;
    data.request_id = requestID;
    const syncer = getQSyncerConn();
    return new Promise((resolve, reject) => {
      try {
        if (syncer.readyState !== WebSocket.OPEN) {
          reject("Not ready.");
        }
        syncer.send(JSON.stringify(data));
        return resolve();
      } catch (error) {
        return reject();
      }
    });
  };

  const promisifySyncCallProfilePush = (data) => {
    return new Promise((resolve, reject) => {
      const recvCallback = (receivedData) => {
        const parsed = JSON.parse(receivedData.data);
        if (parsed.data && parsed.data.error) {
          console.log("Received error from QSyncer.", parsed.data.error);
          _unregisterSyncHandler(this);
          resolve(parsed);
        } else {
          if (parsed.data && parsed.data.success === true) {
            _unregisterSyncHandler(this);
            resolve(parsed);
          }
        }
      };
      _registerSyncHandler(recvCallback);

      promisifySyncCall(data).catch((data) => {
        _unregisterSyncHandler(recvCallback);
        reject(data);
      });
    });
  };

  const promisifyMonitorCall = (data, path) => {
    return new Promise((resolve, reject) => {
      try {
        const monitorWSConnection = new WebSocket(
          config.monitorAbiiWSUrl + path
        );
        monitorWSConnection.onopen = function () {
          console.log("Sending message to monitor");
          monitorWSConnection.send(JSON.stringify(data));
          return resolve();
        };
      } catch (error) {
        return reject(error);
      }
    });
  };

  const qsyncerCall = (data) => {
    return new Promise((resolve, reject) => {
      const recvCallback = (receivedData) => {
        const parsed = JSON.parse(receivedData.data);
        if (parsed.data && parsed.data.error) {
          console.log("Received error from QSyncer.", parsed.data.error);
          _unregisterSyncHandler(this);
          resolve(parsed);
        } else {
          _unregisterSyncHandler(this);
          resolve(parsed);
        }
      };
      _registerSyncHandler(recvCallback);

      promisifySyncCall(data).catch((data) => {
        _unregisterSyncHandler(recvCallback);
        reject(data);
      });
    });
  };

  const api = {
    // Endpoints for cloud system
    getToken: () => {
      return token;
    },
    fetchObjectsForTeacher: (teacherId) => {
      let url = `${config.apiUrl}/teacher/details/`;
      if (teacherId) {
        url += `?id=${teacherId}`;
      }
      return fetchWithError(url, getData());
    },
    fetchIfSyncAvailable: () => {
      return fetchWithError(`${config.apiUrl}/sync-availability/`, getData());
    },
    fetchLearnersInUserTag: (tagId) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/usertag/${tagId}/learners/`,
        getData()
      );
    },
    fetchAllVisibleLearners: (queryParams) => {
      const queryStr = queryParams2Str(queryParams);
      return fetchWithError(
        `${config.apiUrl}/accounts/all-learners/${queryStr}`,
        getData()
      );
    },
    fetchAllVisibleUsers: (queryParams) => {
      const queryStr = queryParams2Str(queryParams);
      return fetchWithError(
        `${config.apiUrl}/accounts/all-users/${queryStr}`,
        getData()
      );
    },
    fetchOrgUnits: (queryParams) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/list-orgunits/?${queryParams}`,
        getData()
      );
    },
    fetchAllVisibleUserTags: () => {
      return fetchWithError(
        `${config.apiUrl}/accounts/all-usertags/`,
        getData()
      );
    },
    fetchAllVisibleOrganizations: (queryParams) => {
      const queryStr = queryParams2Str(queryParams);
      return fetchWithError(
        `${config.apiUrl}/accounts/all-orgs/${queryStr}`,
        getData()
      );
    },
    fetchUserInfo: (userId) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/user/${userId}/`,
        getData()
      );
    },
    patchUserInfo: (userId, data) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/user/${userId}/`,
        patchData(JSON.stringify(data))
      );
    },
    fetchLearnerInfo: (learnerId) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/learner/${learnerId}/`,
        getData()
      );
    },
    fetchUserTagInfo: (userTagId) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/usertag/${userTagId}/`,
        getData()
      );
    },
    fetchRobotTagInfo: (robotTagId) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/robottag/${robotTagId}/`,
        getData()
      );
    },
    fetchUserTagAssociations: (userTagId) => {
      console.log("tagId:", userTagId);
      return fetchWithError(
        `${config.apiUrl}/accounts/usertag/${userTagId}/assoc/`,
        getData()
      );
    },
    fetchRobotTagAssociations: (robotTagId) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/robottag/${robotTagId}/assoc/`,
        getData()
      );
    },
    fetchRobotInfo: (serial) => {
      return fetchWithError(`${config.apiUrl}/robot/${serial}/`, getData());
    },
    fetchRobotSnapshot: (serial) => {
      return fetchWithError(
        `${config.apiUrl}/robot/${serial}/snapshot/`,
        getData()
      );
    },
    fetchAllVisibleRobots: (queryParams) => {
      const queryStr = queryParams2Str(queryParams);
      return fetchWithError(
        `${config.apiUrl}/robot/all/${queryStr}`,
        getData()
      );
    },
    fetchAllUnassignedVisibleRobots: () => {
      return fetchWithError(
        `${config.apiUrl}/robot/all/?q=unassigned&show_all=true&sort=serial`,
        getData()
      );
    },
    fetchRobotsCurrentlyOnline: () => {
      return fetchWithError(`${config.apiUrl}/regent/online/`, getData());
    },
    fetchIsRobotOnline: (serial) => {
      return fetchWithError(
        `${config.apiUrl}/regent/robot-online/?robot=${serial}`,
        getData()
      );
    },
    fetchCachedRobotStatus: (serial) => {
      return fetchWithError(
        `${config.apiUrl}/regent/robot-status/?robot=${serial}`,
        getData()
      );
    },
    fetchRobotStatusFile: (serial) => {
      return fetchWithError(
        `${config.apiUrl}/regent/cat-robot-status/?robot=${serial}`,
        getData()
      );
    },
    UpdateOnlineRobot: (updateOpts) => {
      const data = JSON.stringify(updateOpts);
      return fetchWithError(
        `${config.apiUrl}/regent/update-robot/`,
        postData(data)
      );
    },
    startUpdateAllOnlineRobots: (updateOpts) => {
      const data = JSON.stringify(updateOpts);
      return fetchWithError(
        `${config.apiUrl}/regent/update-all-online/`,
        postData(data)
      );
    },
    cancelUpdateAllOnlineRobots: () => {
      const data = JSON.stringify({ cmd: "CANCEL" });
      return fetchWithError(
        `${config.apiUrl}/regent/update-all-online/`,
        postData(data)
      );
    },
    checkUpdateAllOnlineRobotsProgress: () => {
      return fetchWithError(
        `${config.apiUrl}/regent/update-all-online/`,
        getData()
      );
    },
    pingRobot: (serial) => {
      return fetchWithError(
        `${config.apiUrl}/regent/ping-robot/?serial=${serial}`,
        getData()
      );
    },
    fetchUserOrgHasCloud: () => {
      return fetchWithError(
        `${config.apiUrl}/accounts/org-has-cloud`,
        getData()
      );
    },
    fetchUserSubscriptions: () => {
      return fetchWithError(
        `${config.apiUrl}/accounts/user-subscriptions/`,
        getData()
      );
    },
    fetchRobotsSummary: () => {
      return fetchWithError(`${config.apiUrl}/robots-summary/`, getData());
    },
    fetchRobotsActivationCount: () => {
      return fetchWithError(
        `${config.apiUrl}/robots-activation-count/`,
        getData()
      );
    },
    fetchAllVisibleRobotTags: () => {
      return fetchWithError(
        `${config.apiUrl}/accounts/all-robottags/`,
        getData()
      );
    },
    fetchMyRobotInfo: (serial) => {
      return fetchWithError(`${config.apiUrl}/my-robot/`, getData());
    },
    fetchLearnersForClass: (classId, lessonIds) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/usertag/${classId}/learners/?lesson_ids=${lessonIds}`,
        getData()
      );
    },
    fetchLearnersForMultiClasses: (data) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/multi-usertags/learners/`,
        postData(JSON.stringify(data))
      );
    },
    createUser: (data) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/create-user/`,
        postData(JSON.stringify(data))
      );
    },
    deleteUser: (userId, data) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/user/${userId}/`,
        deleteData()
      );
    },
    createUserCode: () => {
      return fetchWithError(`${config.apiUrl}/create-user-code/`, postData());
    },
    inviteSSOUser: (data) => {
      return fetchWithError(
        `${config.apiUrl}/invite-sso-user/`,
        postData(JSON.stringify(data))
      );
    },
    fetchConnectedAccounts: () => {
      return fetchWithError(`${config.apiUrl}/connected-accounts/`, getData());
    },
    fetchConnectedAccount: (acctId) => {
      return fetchWithError(
        `${config.apiUrl}/connected-accounts/${acctId}/`,
        getData()
      );
    },
    removeConnectedAccount: (acctId) => {
      return fetchWithError(
        `${config.apiUrl}/connected-accounts/${acctId}/`,
        deleteData()
      );
    },
    createLearner: (data) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/create-learner/`,
        postData(JSON.stringify(data))
      );
    },
    createLearners: (learner_info) => {
      const data = { learners: learner_info };
      return fetchWithError(
        `${config.apiUrl}/accounts/create-learners/`,
        postData(JSON.stringify(data))
      );
    },
    createLearnersInUserTag: (tag_id, learner_info) => {
      const data = { usertag_id: tag_id, learners: learner_info };
      return fetchWithError(
        `${config.apiUrl}/accounts/create-learners/`,
        postData(JSON.stringify(data))
      );
    },
    createBulkLearners: (data) => {
      console.log("data:", data);
      const res = axios.post(
        `${config.apiUrl}/accounts/create-bulk-learners/`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${api.getToken()}`,
          },
        }
      );
      return res;
    },
    fetchBulkLearnerTemplate: () => {
      const data = getData();
      return fetchDownloadFile(
        `${config.apiUrl}/accounts/bulk-csv-template/`,
        data
      );
    },
    patchLearnerInfo: (learnerId, data) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/learner/${learnerId}/`,
        patchData(JSON.stringify(data))
      );
    },
    deleteLearner: (learnerId) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/learner/${learnerId}/`,
        deleteData(JSON.stringify())
      );
    },
    createUserTag: (data) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/create-usertag/`,
        postData(JSON.stringify(data))
      );
    },
    patchUserTagInfo: (userTagId, data) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/usertag/${userTagId}/`,
        patchData(JSON.stringify(data))
      );
    },
    deleteUserTag: (userTagId) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/usertag/${userTagId}/`,
        deleteData(JSON.stringify())
      );
    },
    applyUserTagToUser: (tagId, userId) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/user/${userId}/add/usertag/${tagId}/`,
        postData()
      );
    },
    applyUserTagToLearner: (tagId, learnerId) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/learner/${learnerId}/add/usertag/${tagId}/`,
        postData()
      );
    },
    removeUserTagFromUser: (tagId, userId) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/user/${userId}/remove/usertag/${tagId}/`,
        postData()
      );
    },
    removeUserTagFromLearner: (tagId, learnerId) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/learner/${learnerId}/remove/usertag/${tagId}/`,
        postData()
      );
    },
    createRobotTag: (data) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/create-robottag/`,
        postData(JSON.stringify(data))
      );
    },
    patchRobotTagInfo: (robotTagId, data) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/robottag/${robotTagId}/`,
        patchData(JSON.stringify(data))
      );
    },
    deleteRobotTag: (robotTagId) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/robottag/${robotTagId}/`,
        deleteData(JSON.stringify())
      );
    },
    applyRobotTagToUser: (tagId, userId) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/user/${userId}/add/robottag/${tagId}/`,
        postData()
      );
    },
    applyRobotTagToRobot: (tagId, serial) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/robot/${serial}/add/robottag/${tagId}/`,
        postData()
      );
    },
    removeRobotTagFromUser: (tagId, userId) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/user/${userId}/remove/robottag/${tagId}/`,
        postData()
      );
    },
    removeRobotTagFromRobot: (tagId, serial) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/robot/${serial}/remove/robottag/${tagId}/`,
        postData()
      );
    },
    createOrgUnit: (data) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/create-orgunit/`,
        postData(JSON.stringify(data))
      );
    },
    patchOrgUnitInfo: (orgUnitId, data) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/orgunit/${orgUnitId}/`,
        patchData(JSON.stringify(data))
      );
    },
    deleteOrgUnit: (orgUnitId) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/orgunit/${orgUnitId}/`,
        deleteData()
      );
    },
    createOrganization: (data) => {
      const orgInfo = Object.assign({ send_welcome: true }, data);
      return fetchWithError(
        `${config.apiUrl}/accounts/create-org/`,
        postData(JSON.stringify(orgInfo))
      );
    },
    mergeOrganizations: (mergeFromId, mergeToId) => {
      const data = JSON.stringify({
        merge_from: mergeFromId,
        merge_to: mergeToId,
      });
      return fetchWithError(
        `${config.apiUrl}/accounts/merge-org/`,
        postData(data)
      );
    },
    fetchOrganizationInfo: (orgId) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/organization/${orgId}/`,
        getData()
      );
    },
    fetchOrganizationCleverID: (orgId) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/organization/clever-id/?org_id=${orgId}`,
        getData()
      );
    },
    fetchOrgUnitAdminInfo: () => {
      return fetchWithError(
        `${config.apiUrl}/accounts/orgunit-admin-details/`,
        getData()
      );
    },
    fetchAllOrgNames: () => {
      return fetchWithError(
        `${config.apiUrl}/accounts/all-organization-names/`,
        getData()
      );
    },
    patchOrganizationInfo: (orgId, data) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/organization/${orgId}/`,
        patchData(JSON.stringify(data))
      );
    },
    setOrganizationCleverId: (data) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/organization/clever-id/`,
        postData(JSON.stringify(data))
      );
    },
    removeOrganization: (orgId) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/organization/${orgId}/`,
        deleteData()
      );
    },
    createContact: (orgId, data) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/organization/${orgId}/contacts/`,
        postData(JSON.stringify(data))
      );
    },

    fetchContacts: (orgId) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/organization/${orgId}/contacts/`,
        getData()
      );
    },

    fetchContactDetail: (orgId, contactId) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/organization/${orgId}/contacts/${contactId}/`,
        getData()
      );
    },

    patchContactInfo: (orgId, contactId, data) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/organization/${orgId}/contacts/${contactId}/`,
        patchData(JSON.stringify(data))
      );
    },

    deleteContact: (orgId, contactId) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/organization/${orgId}/contacts/${contactId}/`,
        deleteData()
      );
    },
    fetchRobotsInOrg: (queryParams) => {
      const queryStr = queryParams2Str(queryParams);
      return fetchWithError(
        `${config.apiUrl}/robot/in-org/${queryStr}`,
        getData()
      );
    },
    fetchRobotDataForBanners: (queryParams) => {
      const queryStr = queryParams2Str(queryParams);
      return fetchWithError(
        `${config.apiUrl}/robot-mgmt/robot-data-for-banners/${queryStr}`,
        getData()
      );
    },
    patchRobotInfo: (serial, data) => {
      return fetchWithError(
        `${config.apiUrl}/robot/${serial}/`,
        patchData(JSON.stringify(data))
      );
    },
    reassignRobot: (
      serial,
      orgId = null,
      decoupleData = false,
      orgunitIds = null
    ) => {
      let data = {
        serial: serial,
        org_id: orgId,
        decouple_data: decoupleData,
        orgunit_ids: orgunitIds,
      };
      data = JSON.stringify(data);
      return fetchWithError(
        `${config.apiUrl}/robot/reassign-robot/`,
        postData(data)
      );
    },
    bulkAssignRobotsToOrganization: (params) => {
      const data = JSON.stringify(params);
      return fetchWithError(
        `${config.apiUrl}/robot/bulk-assign-to-org/`,
        postData(data)
      );
    },
    decoupleRobotData: (serial, decoupleData = false) => {
      let data = { serial: serial, decouple_data: decoupleData };
      data = JSON.stringify(data);
      return fetchWithError(
        `${config.apiUrl}/robot/decouple-robot-data/`,
        postData(data)
      );
    },
    // Activation Codes
    fetchRegCodesInOrg: (orgId) => {
      if (orgId) {
        const encodedOrg = encodeURIComponent(orgId);
        return fetchWithError(
          `${config.apiUrl}/robot-mgmt/registration/list/?org_id=${encodedOrg}`,
          getData()
        );
      } else {
        // This should return Registration Codes in NO organization
        return fetchWithError(
          `${config.apiUrl}/robot-mgmt/registration/list/`,
          getData()
        );
      }
    },
    createRegCode: (data) => {
      const _data = JSON.stringify(data);
      return fetchWithError(
        `${config.apiUrl}/robot-mgmt/registration/create/`,
        postData(_data)
      );
    },
    RetrieveUpdateDeleteRegCode: (regCodeId, data, method) => {
      const _data = JSON.stringify(data);
      if (method === "patch") {
        return fetchWithError(
          `${config.apiUrl}/robot-mgmt/registration/${regCodeId}/`,
          patchData(_data)
        );
      }
    },
    fetchAccountsInOrg: (queryParams) => {
      const queryStr = queryParams2Str(queryParams);
      return fetchWithError(
        `${config.apiUrl}/accounts/list-users/${queryStr}`,
        getData()
      );
    },
    resetUserPassword: (pw1, pw2) => {
      const data = postData(JSON.stringify({ pw1: pw1, pw2: pw2 }));
      return fetchWithError(`${config.apiUrl}/reset-pw/`, data);
    },
    resetPasswordFromEmail: (pw1, pw2, token) => {
      const data = JSON.stringify({ pw1: pw1, pw2: pw2 });
      return fetchWithError(
        `${config.apiUrl}/reset/${token.uid}/${token.slug}/`,
        postDataNoAuth(data)
      );
    },
    triggerPasswordResetEmail: (userId) => {
      const data = JSON.stringify({ user_id: userId });
      return fetchWithError(
        `${config.apiUrl}/trigger-reset-email/`,
        postData(data)
      );
    },
    requestPasswordResetEmail: (username) => {
      const data = JSON.stringify({ username: username });
      return fetchWithError(
        `${config.apiUrl}/request-reset-email/`,
        postDataNoAuth(data)
      );
    },
    triggerOrgWelcomeEmail: (sendToEmail, RegCodeId) => {
      const data = JSON.stringify({
        send_to: sendToEmail,
        reg_code_id: RegCodeId,
      });
      return fetchWithError(
        `${config.apiUrl}/send-org-welcome-email/`,
        postData(data)
      );
    },
    triggerTeacherWelcomeEmail: (userId, provider = null) => {
      const data = JSON.stringify({ user_id: userId, provider: provider });
      return fetchWithError(
        `${config.apiUrl}/send-teacher-welcome-email/`,
        postData(data)
      );
    },
    checkTeacherWelcomeEmailOptions: (userId) => {
      const data = JSON.stringify({ user_id: userId });
      return fetchWithError(
        `${config.apiUrl}/check-teacher-welcome-email-options/`,
        postData(data)
      );
    },
    requestForgotUsernameEmail: (email) => {
      const data = JSON.stringify({ email: email });
      return fetchWithError(
        `${config.apiUrl}/request-username-email/`,
        postDataNoAuth(data)
      );
    },
    fetchTeachersOnRobot: (serial) => {
      const data = JSON.stringify({ serial: serial });
      return fetchWithError(
        `${config.apiUrl}/accounts/robot-teachers/`,
        postData(data)
      );
    },
    assignTeacherToRobot: (serials, teacherId) => {
      const data = JSON.stringify({ serials: serials, teacher_id: teacherId });
      return fetchWithError(
        `${config.apiUrl}/accounts/assign-robot/`,
        postData(data)
      );
    },
    // Account Registration
    queryOrgCode: (orgCode) => {
      const data = JSON.stringify({ org_code: orgCode });
      return fetchWithError(
        `${config.apiUrl}/accounts/org-code/`,
        postDataNoAuth(data)
      );
    },
    // Security Questions
    //resetUserPassword: (pw1, pw2) => {
    //  const data = postData(JSON.stringify({'pw1': pw1, 'pw2': pw2}));
    //  return fetchWithError(`${config.apiUrl}/accounts/user/reset_pw/`, data)
    //},
    fetchQuestionForUser: (username) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/user/${username}/security_question/`,
        getDataNoAuth()
      );
    },
    answerQuestionForUser: (username, answer) => {
      const data = { answer: answer };
      return fetchWithError(
        `${config.apiUrl}/accounts/user/${username}/security_question/answer/`,
        postDataNoAuth(JSON.stringify(data))
      );
    },
    fetchAuthdQuestionForUser: (username) => {
      return fetchWithError(
        `${config.apiUrl}/accounts/user/${username}/security_question/`,
        getData()
      );
    },
    saveSecurityQuestion: (username, questionID, answer) => {
      const data = { question_identifier: questionID, answer_text: answer };
      return fetchWithError(
        `${config.apiUrl}/accounts/user/security_question/new/`,
        postData(JSON.stringify(data))
      );
    },
    // Lesson Grouping/Context
    fetchLessonReportContextData: (lessonId) => {
      const data = { lesson_id: lessonId };
      return fetchWithError(
        `${config.apiUrl}/reports/lesson-context/`,
        postData(JSON.stringify(data))
      );
    },
    groupLessons: (lessons) => {
      return fetchWithError(
        `${config.apiUrl}/reports/group-lessons/`,
        postData(JSON.stringify(lessons))
      );
    },
    fetchCoursesAndUnits: () => {
      return fetchWithError(
        `${config.apiUrl}/lesson-data/courses-and-units/`,
        getData()
      );
    },
    // lesson assignment
    fetchPairedLessons: (lessonId) => {
      return fetchWithError(
        `${config.apiUrl}/lesson-data/paired-lessons/?unit_ids=${lessonId}`,
        getData()
      );
    },
    fetchAssignments: (page_size, page, status) => {
      return fetchWithError(
        `${config.apiUrl}/manage-assignments/?page_size=${page_size}&page=${page}&status=${status}`,
        getData()
      );
    },
    postAssignment: (data) => {
      return fetchWithError(
        `${config.apiUrl}/manage-assignments/`,
        postData(JSON.stringify(data))
      );
    },
    deleteAssignment: (id) => {
      return fetchWithError(
        `${config.apiUrl}/manage-assignments/${id}/`,
        deleteData()
      );
    },
    editAssignment: (id, data) => {
      return fetchWithError(
        `${config.apiUrl}/manage-assignments/${id}/`,
        patchData(JSON.stringify(data))
      );
    },
    // For Reports
    fetchCourses: (lessonSource) => {
      return fetchWithError(
        `${config.apiUrl}/reports/course-list/?lesson_source=${lessonSource}`,
        getData()
      );
    },
    fetchLessonsForCourse: (cid, lessonSource) => {
      return fetchWithError(
        `${config.apiUrl}/reports/lesson-list/?course=${cid}&lesson_source=${lessonSource}`,
        getData()
      );
    },
    fetchLearnerInstances: (lessonId, learnerId) => {
      const data = { lesson_id: lessonId, learner_id: learnerId };
      return fetchWithError(
        `${config.apiUrl}/reports/all-lesson-instances/`,
        postData(JSON.stringify(data))
      );
    },
    fetchClassAverageReport: (lessonId, clsId) => {
      const data = { lesson_id: lessonId, usertag_id: clsId };
      return fetchWithError(
        `${config.apiUrl}/reports/lesson/class-avg/`,
        postData(JSON.stringify(data))
      );
    },
    fetchOrganizedReportsForClass: (clsId) => {
      const data = { usertag_id: clsId };
      return fetchWithError(
        `${config.apiUrl}/reports/class-report/`,
        postData(JSON.stringify(data))
      );
    },
    fetchLearnerAverageReport: (lessonId, clsId, learnerId) => {
      if (clsId === "undefined" || clsId === "by-learner") {
        const data = { lesson_id: lessonId, learner_id: learnerId };
        return fetchWithError(
          `${config.apiUrl}/reports/lesson/learner-avg/`,
          postData(JSON.stringify(data))
        );
      } else {
        const data = {
          lesson_id: lessonId,
          usertag_id: clsId,
          learner_id: learnerId,
        };
        return fetchWithError(
          `${config.apiUrl}/reports/lesson/learner-avg/`,
          postData(JSON.stringify(data))
        );
      }
    },
    fetchLearnerReportsForLessonInstance: (
      lesson,
      learnerId,
      version,
      date,
      time,
      lesson_uuid
    ) => {
      const data = {
        lesson_id: lesson,
        learner_id: learnerId,
        version: version,
        date: date,
        time: time,
        lesson_uuid: lesson_uuid,
      };
      return fetchWithError(
        `${config.apiUrl}/reports/lesson-instance/`,
        postData(JSON.stringify(data))
      );
    },
    fetchLearnerReportsForLearner: (learnerId) => {
      const data = { learner_id: learnerId };
      return fetchWithError(
        `${config.apiUrl}/reports/lesson/learner-latest/`,
        postData(JSON.stringify(data))
      );
    },
    fetchLessonsForLearner: (learnerId) => {
      return fetchWithError(
        `${config.apiUrl}/reports/learner/${learnerId}/lessons/`,
        getData()
      );
    },
    fetchLessonsForClass: (clsId, lessonSource) => {
      return fetchWithError(
        `${config.apiUrl}/reports/usertag/${clsId}/lessons/?lesson_source=${lessonSource}`,
        getData()
      );
    },
    fetchProfileStats: (learnerId) => {
      return fetchWithError(
        `${config.apiUrl}/reports/stats/learner/${learnerId}/`,
        getData()
      );
    },
    fetchUsageStatsForAdmin: (orgId) => {
      return fetchWithError(
        `${config.apiUrl}/stats/usage/organization/${orgId}/`,
        getData()
      );
    },
    fetchUsageStatsForSuperAdmin: () => {
      return fetchWithError(`${config.apiUrl}/stats/usage/overall/`, getData());
    },
    fetchUsageStatsForOrgUnit: (orgUnitId) => {
      return fetchWithError(
        `${config.apiUrl}/stats/usage/orgunit/${orgUnitId}/`,
        getData()
      );
    },
    fetchHistoricUsageStatsForOrgUnit: (ids) => {
      const idsQueryParam = ids.map((id) => `ids=${id}`).join("&");
      return fetchWithError(
        `${config.apiUrl}/stats/usage/historic/?${idsQueryParam}`,
        getData()
      );
    },
    fetchImpactStatsForOrg: (orgId, lessonSource) => {
      return fetchWithError(
        `${config.apiUrl}/stats/impact/organization/${orgId}/?lesson_source=${lessonSource}`,
        getData()
      );
    },
    fetchImpactStatsForOrgUnit: (orgUnitId, lessonSource) => {
      return fetchWithError(
        `${config.apiUrl}/stats/impact/orgunit/${orgUnitId}/?lesson_source=${lessonSource}`,
        getData()
      );
    },
    fetchImpactStatsForMultipleOrgUnits: (ids, lessonSource) => {
      const idsQueryParam = ids.map((id) => `ids=${id}`).join("&");
      return fetchWithError(
        `${config.apiUrl}/stats/impact/orgunit/?${idsQueryParam}&lesson_source=${lessonSource}`,
        getData()
      );
    },
    exportImpactStats: (data) => {
      return fetchDownloadFile(
        `${config.apiUrl}/stats/impact/export/`,
        postData(JSON.stringify(data))
      );
    },
    fetchUserAudioStatus: (learnerId) => {
      return fetchWithError(
        `${config.apiUrl}/user-audio/?learner_id=${learnerId}&audio_type=learner_name`,
        getData()
      );
    },
    setUserAudio: (learnerId, newAudio) => {
      const data = { audio_file_content: newAudio };
      return fetchWithError(
        `${config.apiUrl}/user-audio/${learnerId}/`,
        patchData(JSON.stringify(data))
      );
    },

    // Sales
    fetchLatestPrices: (saleId) => {
      return fetchWithError(
        `${config.apiBaseUrl}/sales/latest-prices/`,
        getData()
      );
    },
    fetchSaleList: (queryParams) => {
      const queryStr = queryParams2Str(queryParams);
      return fetchWithError(
        `${config.apiBaseUrl}/sales/manage-sales/${queryStr}`,
        getData()
      );
    },
    fetchSaleDetail: (saleId) => {
      return fetchWithError(
        `${config.apiBaseUrl}/sales/manage-sales/${saleId}/`,
        getData()
      );
    },
    createSale: (saleData) => {
      const data = postData(JSON.stringify(saleData));
      return fetchWithError(`${config.apiBaseUrl}/sales/manage-sales/`, data);
    },
    editSale: (saleId, saleData) => {
      const data = patchData(JSON.stringify(saleData));
      return fetchWithError(
        `${config.apiBaseUrl}/sales/manage-sales/${saleId}/`,
        data
      );
    },
    deleteSale: (saleId) => {
      const data = deleteData();
      return fetchWithError(
        `${config.apiBaseUrl}/sales/manage-sales/${saleId}/`,
        data
      );
    },
    updateSaleStatus: (saleId, newStatus) => {
      const data = postData(
        JSON.stringify({
          sale: saleId,
          new_status: newStatus,
        })
      );
      return fetchWithError(
        `${config.apiBaseUrl}/sales/update-sale-status/`,
        data
      );
    },
    patchSaleStatus: (saleStatusId, editData) => {
      const data = patchData(JSON.stringify(editData));
      return fetchWithError(
        `${config.apiBaseUrl}/sales/manage-sale-status-changes/${saleStatusId}/`,
        data
      );
    },
    fetchPdsInSale: (saleId) => {
      return fetchWithError(
        `${config.apiBaseUrl}/sales/manage-pds/?saleId=${saleId}`,
        getData()
      );
    },
    schedulePd: (pdData) => {
      const data = postData(JSON.stringify(pdData));
      return fetchWithError(`${config.apiBaseUrl}/sales/manage-pds/`, data);
    },
    editPd: (pdId, pdData) => {
      const data = patchData(JSON.stringify(pdData));
      return fetchWithError(
        `${config.apiBaseUrl}/sales/manage-pds/${pdId}/`,
        data
      );
    },
    fetchSubscriptions: (queryParams) => {
      const queryStr = queryParams2Str(queryParams);
      return fetchWithError(
        `${config.apiBaseUrl}/sales/manage-subscriptions/${queryStr}`,
        getData()
      );
    },
    fetchAbiiContentPackages: () => {
      return fetchWithError(
        `${config.apiBaseUrl}/sales/abii-content-packages/`,
        getData()
      );
    },
    fetchSubscriptionsTypesInfo: (queryParams) => {
      // obj_type, sub_type, detail_view, active, available_only
      const queryStr = queryParams2Str(queryParams);
      return fetchWithError(
        `${config.apiBaseUrl}/sales/get-subscriptions-types-info/${queryStr}`,
        getData()
      );
    },
    fetchSubscriptionInfo: (subId) => {
      return fetchWithError(
        `${config.apiBaseUrl}/sales/manage-subscriptions/${subId}/`,
        getData()
      );
    },
    editSubscription: (subId, subData) => {
      const data = patchData(JSON.stringify(subData));
      return fetchWithError(
        `${config.apiBaseUrl}/sales/manage-subscriptions/${subId}/`,
        data
      );
    },
    manageSubscriptionObjects: (dict) => {
      const data = postData(JSON.stringify(dict));
      return fetchWithError(
        `${config.apiBaseUrl}/sales/manage-subscription-objects/`,
        data
      );
    },
    assignSubscriptionsToUser: (dict) => {
      const data = postData(JSON.stringify(dict));
      return fetchWithError(
        `${config.apiBaseUrl}/sales/assign-subscriptions-to-user/`,
        data
      );
    },
    manageUserSubscriptions: (dict) => {
      const data = postData(JSON.stringify(dict));
      return fetchWithError(
        `${config.apiBaseUrl}/sales/manage-user-subscriptions/`,
        data
      );
    },
    fetchRenewalInfo: (renewalId) => {
      return fetchWithError(
        `${config.apiBaseUrl}/sales/manage-renewals/${renewalId}/`,
        getData()
      );
    },
    patchRenewalInfo: (renewalId, _data) => {
      const data = patchData(JSON.stringify(_data));
      return fetchWithError(
        `${config.apiBaseUrl}/sales/manage-renewals/${renewalId}/`,
        data
      );
    },
    checkForRenewalsWithoutRobots: (orgId) => {
      return fetchWithError(
        `${config.apiBaseUrl}/sales/check-for-renewals-without-robots/?org_id=${orgId}`,
        getData()
      );
    },
    exportSalesFile: (exportData) => {
      const data = postData(JSON.stringify(exportData));
      return fetchDownloadFile(
        `${config.apiBaseUrl}/sales/export-sales-file/`,
        data
      );
    },
    fetchITSetup: (saleId) => {
      return fetchWithError(
        `${config.apiBaseUrl}/sales/manage-it-setup/?sale_id=${saleId}`,
        getData()
      );
    },
    patchITSetup: (ITdata) => {
      const data = postData(JSON.stringify(ITdata));
      return fetchWithError(
        `${config.apiBaseUrl}/sales/manage-it-setup/`,
        data
      );
    },
    exportRobots: (exportData = {}) => {
      const data = postData(JSON.stringify(exportData));
      return fetchDownloadFile(`${config.apiUrl}/export-robots/`, data);
    },
    exportSubscriptions: (exportData = {}) => {
      const data = postData(JSON.stringify(exportData));
      return fetchDownloadFile(
        `${config.apiBaseUrl}/sales/export-subscriptions/`,
        data
      );
    },
    exportSubsWithRobotCount: (exportData = {}) => {
      const data = postData(JSON.stringify(exportData));
      return fetchDownloadFile(
        `${config.apiBaseUrl}/sales/export-subscriptions-with-robot-count/`,
        data
      );
    },
    createSuperAdminSchool: (schoolData = {}) => {
      const data = postData(JSON.stringify(schoolData));
      return fetchWithError(
        `${config.apiBaseUrl}/sales/create-super-admin-school/`,
        data
      );
    },
    fetchSuperAdminSchools: (orgId = null) => {
      if (orgId) {
        return fetchWithError(
          `${config.apiBaseUrl}/sales/list-super-admin-school/?orgId=${orgId}`,
          getData()
        );
      } else {
        return fetchWithError(
          `${config.apiBaseUrl}/sales/list-super-admin-school/`,
          getData()
        );
      }
    },
    fetchSaleReturns: (saleId) => {
      return fetchWithError(
        `${config.apiBaseUrl}/sales/list-sale-returns/?saleId=${saleId}`,
        getData()
      );
    },
    createSaleReturns: (returnData) => {
      const data = postData(JSON.stringify(returnData));
      return fetchWithError(
        `${config.apiBaseUrl}/sales/create-sale-returns/`,
        data
      );
    },
    deleteSuperAdminSchool: (schoolId) => {
      return fetchWithError(
        `${config.apiBaseUrl}/sales/super-admin-school/${schoolId}/`,
        deleteData()
      );
    },
    patchSuperAdminSchool: (schoolId, data) => {
      return fetchWithError(
        `${config.apiBaseUrl}/sales/super-admin-school/${schoolId}/`,
        patchData(JSON.stringify(data))
      );
    },

    // Endpoints from robot frontend
    getToken: () => {
      return token;
    },
    wifiScan: () => {
      return fetchWithError(`${config.wifiApiUrl}/wifi-scan/`, getData());
    },
    wifiReboot: () => {
      return fetchWithError(`${config.wifiApiUrl}/reboot/`, postData());
    },
    wifiConfig: (wifiConfig) => {
      const data = postData(JSON.stringify(wifiConfig));
      return fetchWithError(`${config.wifiApiUrl}/wifi-config/`, data);
    },
    fetchWifiStatus: (fullCheck = true, forceCheck = false) => {
      return fetchWithError(
        `${config.wifiApiUrl}/status/?full_check=${fullCheck}&force_check=${forceCheck}`,
        getData()
      );
    },
    fetchClasses: () => {
      return fetchWithError(`${config.apiUrl}/classes/`, getData());
    },
    fetchFirstClass: () => {
      return fetchWithError(`${config.apiUrl}/classes/?first=1`, getData());
    },
    fetchSerialNumber: () => {
      return fetchWithError(`${config.apiUrl}/serial-number/`, getData());
    },
    fetchRobotType: () => {
      return fetchWithError(`${config.apiUrl}/robot-type/`, getDataNoAuth());
    },
    fetchClass: (clsId) => {
      return fetchWithError(`${config.apiUrl}/classes/${clsId}/`, getData());
    },
    addLearnersToClass: (clsId, learners) => {
      const data = postData(JSON.stringify(learners));
      return fetchWithError(
        `${config.apiUrl}/classes/${clsId}/add_new_learners/`,
        data
      );
    },
    removeLearnerFromClass: (clsId, learnerId) => {
      const data = deleteData();
      return fetchWithError(
        `${config.apiUrl}/classes/${clsId}/remove_learner/${learnerId}/`,
        data
      );
    },
    deleteClass: (clsId) => {
      const data = deleteData();
      return fetchWithError(
        `${config.apiUrl}/classes/${clsId}/remove_class/`,
        data
      );
    },
    deleteAccount: (password) => {
      const data = deleteData(JSON.stringify({ password: password }));
      return fetchWithError(`${config.apiUrl}/accounts/remove-self/`, data);
    },
    resetLearnerPassword: (learnerId, pw1, pw2) => {
      const data = postData(JSON.stringify({ pw1: pw1, pw2: pw2 }));
      return fetchWithError(
        `${config.apiUrl}/learner/${learnerId}/reset_pw/`,
        data
      );
    },
    updateLearnerGradeInfo: (learnerId, grade_info) => {
      const data = postData(
        JSON.stringify({ learner_id: learnerId, grade_info: grade_info })
      );
      return fetchWithError(
        `${config.apiUrl}/accounts/learner/update_grade_info/`,
        data
      );
    },
    assignLessons: (user, class_id, lesson_ids, learner_ids) => {
      const data = postData(
        JSON.stringify({
          user: user,
          class_id: class_id,
          lesson_ids: lesson_ids,
          learner_ids: learner_ids,
        })
      );
      return fetchWithError(
        `${config.apiUrl}/accounts/learner/assign_lessons/`,
        data
      );
    },
    fetchClassesForLearner: () => {
      return fetchWithError(`${config.apiUrl}/learner/classes/`, getData());
    },
    updateClassName: (id, newClassName) => {
      const data = postData(JSON.stringify({ name: newClassName }));
      return fetchWithError(
        `${config.apiUrl}/classes/${id}/change_name/`,
        data
      );
    },
    addNewClass: (className) => {
      const data = postData(JSON.stringify({ name: className }));
      return fetchWithError(`${config.apiUrl}/classes/add_new_class/`, data);
    },
    fetchTeacherFilterOptions: () => {
      return fetchWithError(
        `${config.apiUrl}/teacher-filter-options/`,
        getData()
      );
    },
    fetchRobotAdmin: () => {
      return fetchWithError(
        `${config.apiUrl}/robot-admin-info/`,
        getDataNoAuth()
      );
    },
    qsyncerGetReadyState: () => {
      const syncer = getQSyncerConn();
      return syncer.readyState;
    },
    qsyncerResolveWhenReady: () => {
      const syncer = getQSyncerConn();
      console.log("Registering qsyncer resolve cb. On syncer: ", syncer);
      return new Promise((resolve, reject) => {
        const ready = () => {
          console.log("QSyncer ready, resolving.");
          resolve();
        };

        if (syncer.readyState === WebSocket.OPEN) {
          ready();
        }

        syncer.addEventListener("open", () => {
          syncer.removeEventListener("open", this);
          ready();
        });
      });
    },
    registerSyncHandler: _registerSyncHandler,
    unregisterSyncHandler: _unregisterSyncHandler,
    registerSyncErrHandler: (callback) => {
      const qsyncerWSConnection = getQSyncerConn();
      qsyncerWSConnection.addEventListener("error", callback);
    },
    fetchSyncEvents: () => {
      const data = {
        type: "SYNC_EVENTS",
      };
      return promisifySyncCall(data);
    },
    fetchLastSyncStatus: () => {
      const data = {
        type: "LAST_SYNC_STATUS",
      };
      return promisifySyncCall(data);
    },
    syncPushUserAccountData: (token) => {
      const data = {
        type: "PUSH",
        data_type: "ACCOUNT",
        token: token,
      };
      return qsyncerCall(data);
    },
    syncPushUserProfileData: (token) => {
      const data = {
        type: "PUSH",
        data_type: "PROFILE",
        token: token,
      };
      return promisifySyncCallProfilePush(data);
    },
    syncPullUserProfileData: (token) => {
      const data = {
        type: "PULL",
        data_type: "PROFILE",
        token: token,
      };
      return qsyncerCall(data);
    },
    syncUpdateRobot: (token) => {
      const self = this;
      const data = {
        type: "PULL",
        data_type: "ROBOT",
        token: token,
      };
      return qsyncerCall(data);
    },
    checkQSyncerStatus: () => {
      return new Promise((resolve, reject) => {
        const monitorWSConnection = new WebSocket(
          config.monitorAbiiWSUrl + "qsync_status"
        );
        monitorWSConnection.onmessage = (data) => {
          console.log("Received message from monitor.");
          console.log(data);
          monitorWSConnection.close();
          resolve(data);
        };

        monitorWSConnection.onopen = () => {
          console.log("Sending QSyncer status query to monitor");
          monitorWSConnection.send(true);
        };

        monitorWSConnection.onerror = () => {
          console.log("Could not connect to monitor.");
          resolve(false);
        };
      });
    },
    shutdownRobot: () => {
      const data = {
        action: "KILL",
        target: "ROBOT",
      };
      return promisifyMonitorCall(data, "poweroff");
    },
    newclassaudio: () => {
      const data = {
        action: "PLaY",
        target: "NEWCLASSAUDIO",
      };
      return promisifyMonitorCall(data, "frontendaudio");
    },
    studentsaved: () => {
      const data = {
        action: "PLaY",
        target: "STUDENTSAVEDAUDIO",
      };
      return promisifyMonitorCall(data, "frontendaudio");
    },
    nostudent: () => {
      const data = {
        action: "PLaY",
        target: "NOSTUDENTAUDIO",
      };
      return promisifyMonitorCall(data, "frontendaudio");
    },
    wifigood: () => {
      const data = {
        action: "PLaY",
        target: "WIFIGOODAUDIO",
      };
      return promisifyMonitorCall(data, "frontendaudio");
    },
    wifinoresources: () => {
      const data = {
        action: "PLaY",
        target: "WIFINORESOURCESAUDIO",
      };
      return promisifyMonitorCall(data, "frontendaudio");
    },
    welcomelogin: () => {
      const data = {
        action: "PLaY",
        target: "WELCOMELOGINAUDIO",
      };
      return promisifyMonitorCall(data, "frontendaudio");
    },
    wifisetupaudio: () => {
      const data = {
        action: "PLaY",
        target: "WIFISETUPAUDIO",
      };
      return promisifyMonitorCall(data, "frontendaudio");
    },
    loginaudio: () => {
      const data = {
        action: "PLaY",
        target: "LOGINAUDIO",
      };
      return promisifyMonitorCall(data, "frontendaudio");
    },
    wifiselectaudio: () => {
      const data = {
        action: "PLaY",
        target: "WIFISCANAUDIO",
      };
      return promisifyMonitorCall(data, "frontendaudio");
    },
    pu_wificonnect: () => {
      const data = {
        action: "PLaY",
        target: "UP_WIFICONNECTAUDIO",
      };
      return promisifyMonitorCall(data, "frontendaudio");
    },
    p_wificonnect: () => {
      const data = {
        action: "PLaY",
        target: "P_WIFICONNECTAUDIO",
      };
      return promisifyMonitorCall(data, "frontendaudio");
    },
    wifireboot: () => {
      const data = {
        action: "PLaY",
        target: "WIFIREBOOTAUDIO",
      };
      return promisifyMonitorCall(data, "frontendaudio");
    },
    newaccountaudio: () => {
      const data = {
        action: "PLaY",
        target: "NEWACCOUNTAUDIO",
      };
      return promisifyMonitorCall(data, "frontendaudio");
    },
    classreturnaudio: () => {
      const data = {
        action: "PLaY",
        target: "CLASSRETURNAUDIO",
      };
      return promisifyMonitorCall(data, "frontendaudio");
    },
    newstudentaudio: () => {
      const data = {
        action: "PLaY",
        target: "NEWSTUDENTAUDIO",
      };
      return promisifyMonitorCall(data, "frontendaudio");
    },
    createaccountaudio: () => {
      const data = {
        action: "PLaY",
        target: "CREATEACCOUNTAUDIO",
      };
      return promisifyMonitorCall(data, "frontendaudio");
    },
    studentloginaudio: (learner_id) => {
      const data = {
        action: "PLaY",
        target: "STUDENTLOGINAUDIO",
        learner_id: learner_id,
      };
      return promisifyMonitorCall(data, "frontendaudio");
    },
    securityaudio: () => {
      const data = {
        action: "PLaY",
        target: "SECURITYAUDIO",
      };
      return promisifyMonitorCall(data, "frontendaudio");
    },
    studentnamesaudio: () => {
      const data = {
        action: "PLaY",
        target: "STUDENTNAMESAUDIO",
      };
      return promisifyMonitorCall(data, "frontendaudio");
    },
    homeflow_returnlaunch: () => {
      const data = {
        action: "PLaY",
        target: "HOMEFLOWRETURNLAUNCH",
      };
      return promisifyMonitorCall(data, "frontendaudio");
    },
    homeflow_returnsave: () => {
      const data = {
        action: "PLaY",
        target: "HOMEFLOWRETURNSAVE",
      };
      return promisifyMonitorCall(data, "frontendaudio");
    },
    createclassaudio: () => {
      const data = {
        action: "PLaY",
        target: "CREATECLASSAUDIO",
      };
      return promisifyMonitorCall(data, "frontendaudio");
    },

    // End robot-specific endpoints
  };

  return api;
};

const withAPI = (wrappedComponent) => {
  return ({ token, user, ...rest }) => {
    const api = apiFactory(token, user);
    return wrappedComponent({ api, token, user, ...rest });
  };
};

export default compose(connect(mapStateToProps), withAPI);
